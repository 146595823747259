import Index from "../views/Index";
import Product from "../views/Product";
import Service from "../views/Service";
import Home from "../views/Home";
import {Navigate} from "react-router";

const routes = [
    {path: "/", element: <Navigate to="/home/index" replace={true}/>},
    {
        path: "/home",
        element: <Home/>,
        children: [
            {path: "index", element: <Index/>},
            {path: "product", element: <Product/>},
            {path: "service", element: <Service/>},
        ]
    },
]

export {routes}
import {MailFill, MessageFill, StarFill} from "antd-mobile-icons";

export default function Index() {
    return <div className="container">
        <h3><StarFill/>公开信息</h3>
        <p>企业名称：官渡区红伞软件开发工作室</p>
        <p>统一社会信用代码：92530111MACFN7BX7L</p>
        <p>经营者：张金龙</p>
        <p>经营状态：开业</p>
        <p>成立日期：2023-05-05</p>
        <p>行政区划：云南省昆明市官渡区</p>
        <p>注册资本：-</p>
        <p>实缴资本：-</p>
        <p>企业类型：个体工商户</p>
        <p>所属行业：软件和信息技术服务业</p>
        <p>工商注册号：530111603355281</p>
        <p>组织机构代码：MACFN7BX-7</p>
        <p>纳税人识别号：92530111MACFN7BX7L</p>
        <p>纳税人资质：-</p>
        <p>营业期限：2023-05-05 至 无固定期限</p>
        <p>核准日期：2023-05-05</p>
        <p>登记机关：官渡区市场监督管理局</p>
        <p>参保人数：-</p>
        <p>曾用名：-</p>
        <p>注册地址：中国（云南）自由贸易试验区昆明片区官渡区矣六街道办事处俊发观云海云漫岭5B栋2305</p>
        <p>经营范围：一般项目：软件开发；信息技术咨询服务；网络技术服务；互联网安全服务；人工智能基础软件开发；企业管理咨询；教育咨询服务（不含涉许可审批的教育培训活动）；信息系统集成服务；信息咨询服务（不含许可类信息咨询服务）；物联网应用服务；物联网技术服务；网络与信息安全软件开发（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）。</p>
        <h3><StarFill/>企业驻地</h3>
        <iframe
            src="https://map.baidu.com/mobile/webapp/place/detail/foo=bar&qt=ninf&wd=%E5%85%AC%E5%8F%B8%E5%9C%B0%E5%9D%80&c=131&searchFlag=sort&center_rank=1&nb_x=11441467.14&nb_y=2849086.22&da_src=unifynearbyclick&uid=1dd9b71e0d6210e401294fbb&industry=&qid=&pos=&da_ref=&da_qrtp=&da_adquery=&da_adtitle=%E4%BF%8A%E5%8F%91%E8%A7%82%E4%BA%91%E6%B5%B7%E4%BA%91%E6%BC%AB%E5%B2%AD-5B%E6%A0%8B&da_adindus=%E6%88%BF%E5%9C%B0%E4%BA%A7;%E5%86%85%E9%83%A8%E6%A5%BC%E6%A0%8B&detail_from=list&vt=map/?fromhash=1"/>
        <h3><StarFill/>联系方式</h3>
        <p><MessageFill/> 微信：rustgopy</p>
        <p><MailFill/> 邮箱：thznote@qq.com</p>
    </div>
}
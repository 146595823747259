import {useRoutes,} from 'react-router'

import './App.less';
import {routes} from "./routes";


function App() {
    return useRoutes(routes)
}

export default App;

import {StarFill} from "antd-mobile-icons";

export default function Product() {
    return <div className="container">
        <h3><StarFill/>内部产品（联系我们远程演示）</h3>
        <p>1.红伞安全中心</p>
        <p>2.漏洞扫描系统</p>
        <p>3.WEB应用防火墙</p>
        <p>4.蜜罐攻击情报收集系统</p>
        <p>5.区块链C2远控系统</p>
        <p>6.网络空间测绘系统</p>
        <p>7.门店点餐系统</p>
        <p>8.多端多商户商城</p>
        <p>9.抖音采集系统</p>
        <p>10.考试ERP</p>
        <p>11.博客</p>
        <p>12.区块链隧道</p>
        <p>13.区块链聊天</p>
        <p>14.未完待续......</p>
        <h3><StarFill/>线上产品（关注公众号统一访问）</h3>
        <p>
            <img src="https://rustgopy-1251580444.cos.ap-beijing.myqcloud.com/tools/wx.png"/>
        </p>
        <p>1.哆啦G梦AI机器人</p>
        <p>2.红伞轻创</p>
        <p>3.红伞工具箱大全</p>
        <p>4.鬼眯表情</p>
        <p>5.未完待续......</p>
    
    </div>
}
import {Space, Tag} from "antd-mobile";
import {StarFill} from "antd-mobile-icons";

export default function Service() {
    return <div className="container">
        <h3><StarFill/>团队掌握技能墙</h3>
        <p>
            <Space wrap>
                <Tag color='#87d068' fill='outline'>小程序</Tag>
                <Tag color='#87d068' fill='outline'>H5</Tag>
                <Tag color='#87d068' fill='outline'>HTML5</Tag>
                <Tag color='#87d068' fill='outline'>CSS3</Tag>
                <Tag color='#87d068' fill='outline'>Web</Tag>
                <Tag color='#87d068' fill='outline'>JavaScript</Tag>
                <Tag color='#87d068' fill='outline'>React</Tag>
                <Tag color='#87d068' fill='outline'>Vue</Tag>
                <Tag color='#87d068' fill='outline'>uni-app</Tag>
                <Tag color='#87d068' fill='outline'>three.js</Tag>
                <Tag color='#87d068' fill='outline'>wasm</Tag>
                <Tag color='#87d068' fill='outline'>WebAssembly</Tag>
                <Tag color='#87d068' fill='outline'>Android开发</Tag>
                <Tag color='#87d068' fill='outline'>iOS开发</Tag>
                <Tag color='#87d068' fill='outline'>各类UI框架</Tag>
                <Tag color='#87d068' fill='outline'>各类前端开发</Tag>
                <Tag color='#87d068' fill='outline'>大屏开发</Tag>
                <Tag color='#87d068' fill='outline'>数字孪生</Tag>
                <Tag color='#87d068' fill='outline'>中后台开发</Tag>
                <Tag color='primary' fill='outline'>Java</Tag>
                <Tag color='primary' fill='outline'>SpringBoot</Tag>
                <Tag color='primary' fill='outline'>SpringCloud</Tag>
                <Tag color='primary' fill='outline'>Python</Tag>
                <Tag color='primary' fill='outline'>Flask</Tag>
                <Tag color='primary' fill='outline'>Django</Tag>
                <Tag color='primary' fill='outline'>Tornado</Tag>
                <Tag color='primary' fill='outline'>Go</Tag>
                <Tag color='primary' fill='outline'>Gin</Tag>
                <Tag color='primary' fill='outline'>Beego</Tag>
                <Tag color='primary' fill='outline'>Lua</Tag>
                <Tag color='primary' fill='outline'>Rust</Tag>
                <Tag color='primary' fill='outline'>C/C++/C#</Tag>
                <Tag color='primary' fill='outline'>微服务</Tag>
                <Tag color='primary' fill='outline'>分布式</Tag>
                <Tag color='primary' fill='outline'>数据分析</Tag>
                <Tag color='primary' fill='outline'>大模型</Tag>
                <Tag color='primary' fill='outline'>人工智能</Tag>
                <Tag color='primary' fill='outline'>AIGC</Tag>
                <Tag color='primary' fill='outline'>MySQL</Tag>
                <Tag color='primary' fill='outline'>PgSQL</Tag>
                <Tag color='primary' fill='outline'>SQLite</Tag>
                <Tag color='primary' fill='outline'>Oracle</Tag>
                <Tag color='primary' fill='outline'>SQLServer</Tag>
                <Tag color='primary' fill='outline'>Redis</Tag>
                <Tag color='primary' fill='outline'>Memcached</Tag>
                <Tag color='primary' fill='outline'>RabbitMQ</Tag>
                <Tag color='primary' fill='outline'>Solr</Tag>
                <Tag color='primary' fill='outline'>ElasticSearch</Tag>
                <Tag color='primary' fill='outline'>MeiliSearch</Tag>
                <Tag color='primary' fill='outline'>MongoDB</Tag>
                <Tag color='primary' fill='outline'>各类数据库</Tag>
                <Tag color='primary' fill='outline'>Linux</Tag>
                <Tag color='primary' fill='outline'>服务器运维</Tag>
                <Tag color='primary' fill='outline'>若依</Tag>
                <Tag color='#ff6430' fill='outline'>网络爬虫</Tag>
                <Tag color='#ff6430' fill='outline'>网络安全</Tag>
                <Tag color='#ff6430' fill='outline'>自动化开发</Tag>
                <Tag color='#ff6430' fill='outline'>Windows逆向</Tag>
                <Tag color='#ff6430' fill='outline'>Android逆向</Tag>
                <Tag color='#ff6430' fill='outline'>小程序逆向</Tag>
                <Tag color='#ff6430' fill='outline'>小程序正向</Tag>
                <Tag color='#ff6430' fill='outline'>反混淆</Tag>
                <Tag color='#ff6430' fill='outline'>反汇编</Tag>
                <Tag color='#ff6430' fill='outline'>脱壳</Tag>
                <Tag color='#ff6430' fill='outline'>游戏辅助</Tag>
                <Tag color='default'>未完待续...</Tag>
            </Space>
        </p>
    </div>
}
import {Outlet, useLocation, useNavigate} from "react-router";
import {AppOutline, AppstoreOutline, UserCircleOutline} from "antd-mobile-icons";
import {NavBar, TabBar} from "antd-mobile";
import React from "react";

const Bottom = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {pathname} = location
    
    const setRouteActive = (value) => {
        navigate(value)
    }
    
    const tabs = [
        {
            key: '/home/index',
            title: '关于我们',
            icon: <UserCircleOutline/>,
        },
        {
            key: '/home/product',
            title: '产品案例',
            icon: <AppstoreOutline/>,
        },
        {
            key: '/home/service',
            title: '技术服务',
            icon: <AppOutline/>,
        },
    ]
    
    return (
        <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title}/>
            ))}
        </TabBar>
    )
}

export default function Home() {
    return (
        <div className="app">
            <div className="top">
                <NavBar>红伞软件</NavBar>
            </div>
            <div className="body">
                <Outlet/>
            </div>
            <div className="bottom">
                <Bottom/>
            </div>
        </div>
    );
}